<template>
  <div :style="skin.menu_sports.nav_bar" class="px-3 py-3 pb-1 m-1">
      <div class="px-3 pe-3">
            <carousel
            :paginationEnabled="false"
            :autoplay="false"
            :navigationEnabled="true"
            :autoplayTimeout="3000"
            :autoplayHoverPause="true"
            :perPageCustom="[[300, 5],[451, 6],[1024, 7]]">
                <slide v-for="(sport) in sports" v-bind:key="sport.name">
                    <div v-if="sport.name==translation['bet_records'][language]">
                        <div class="center_div"><div @click="show_records()" :class="sport.icon" :style="skin.menu_sports.icon"></div></div>
                        <div class="text-center"><span class="long_text my-1" :style="skin.menu_sports.icon_text">{{ sport.name }}</span></div>
                    </div>
                    <div v-else-if="sport.name==translation['live'][language]">
                            <div class="center_div">
                                <router-link
                                    :to="user.token == '' ? { name: 'LiveMainEcuabets', query: { token: user.token+user.site_id, target:'ecua365-86146-owned'}} : { name: 'Live_ecuabets',  query: { token: user.token+user.site_id, target:'ecua365-86146-owned'}}"
                                >
                                    <div :class="sport.icon" :style="skin.menu_sports.icon"></div>
                                </router-link>
                            </div>
                            <div class="text-center"><span class="long_text my-1" :style="skin.menu_sports.icon_text">{{ sport.name }}</span></div>
                    </div>
                    <div v-else>
                        <div class="center_div"><div @click="select_sport(sport)" :class="sport.icon" :style="skin.menu_sports.icon"></div></div>
                        <div class="text-center"><span class="long_text my-1" :style="skin.menu_sports.icon_text">{{ sport.name }}</span></div>
                    </div>
                </slide>
            </carousel>
      </div>
  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
     name:"menu_sports",
     components:{Carousel,Slide},
     props:['skin','translation','language'],
     data(){
         return{
             sports:[],
             render:true
         }
     },
     computed:{
         user(){
            return this.$store.getters['getUserData'];
         }
     },
     methods:{
        select_sport(data){
            this.$store.dispatch('setLobbyView','sport');
            this.$root.$emit('select_sport',data);
        },
        show_records(){
            this.$store.dispatch('setLobbyView','records');
        }
    },
    created(){
        if(this.user.token!=''){
            this.sports=[
                {name:this.$props.translation['bet_records'][this.$props.language],icon:"icon_historial"},
                {name:this.$props.translation['live'][this.$props.language],icon:"icon_live"},
                {name:this.$props.translation['soccer'][this.$props.language],icon:"icon_futbol",id_prematch:1,id_live:1},
                {name:this.$props.translation['basket'][this.$props.language],icon:"icon_basket",id_prematch:18,id_live:3},
                {name:this.$props.translation['tennis'][this.$props.language],icon:"icon_tenis",id_prematch:13,id_live:4},
                {name:this.$props.translation['baseball'][this.$props.language],icon:"icon_beisball",id_prematch:16,id_live:11},
                {name:this.$props.translation['american_futbol'][this.$props.language],icon:"icon_futAmericano",id_prematch:12,id_live:6},
                {name:this.$props.translation['hockey'][this.$props.language],icon:"icon_hockey",id_prematch:17,id_live:2},
                {name:this.$props.translation['boxing'][this.$props.language],icon:"icon_boxeo",id_prematch:9,id_live:17},
                {name:this.$props.translation['table_tennis'][this.$props.language],icon:"icon_tenis_mesa",id_prematch:92,id_live:41},
                {name:this.$props.translation['cricket'][this.$props.language],icon:"icon_cricket",id_prematch:3,id_live:19}
            ]
        }
        else{
            this.sports=[
                {name:this.$props.translation['live'][this.$props.language],icon:"icon_live"},
                {name:this.$props.translation['soccer'][this.$props.language],icon:"icon_futbol",id_prematch:1,id_live:1},
                {name:this.$props.translation['basket'][this.$props.language],icon:"icon_basket",id_prematch:18,id_live:3},
                {name:this.$props.translation['tennis'][this.$props.language],icon:"icon_tenis",id_prematch:13,id_live:4},
                {name:this.$props.translation['baseball'][this.$props.language],icon:"icon_beisball",id_prematch:16,id_live:11},
                {name:this.$props.translation['american_futbol'][this.$props.language],icon:"icon_futAmericano",id_prematch:12,id_live:6},
                {name:this.$props.translation['hockey'][this.$props.language],icon:"icon_hockey",id_prematch:17,id_live:2},
                {name:this.$props.translation['boxing'][this.$props.language],icon:"icon_boxeo",id_prematch:9,id_live:17},
                {name:this.$props.translation['table_tennis'][this.$props.language],icon:"icon_tenis_mesa",id_prematch:92,id_live:41},
                {name:this.$props.translation['cricket'][this.$props.language],icon:"icon_cricket",id_prematch:3,id_live:19}
            ]
        }
    },
}
</script>

<style>
    .center_div{
        width: 40px; /* Can be in percentage also. */
        height: auto;
        margin: 0 auto;
        padding: 0px;
        position: relative;
    }
    .long_text {
      display: -webkit-box;
      max-width: 200px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      -webkit-box-align: center;
      overflow: hidden;
  }
</style>