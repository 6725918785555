<template>
  <div v-if="skin_charged" style="color:white;position:relative">
    <div>
        <main-header
        v-if="screen_width>selected_skin.break_point"
        v-bind:skin="selected_skin"
        v-bind:language="language"
        v-bind:translation="translation"
        />
        <div v-else class="fixed-top">
            <main-header-mobil
                v-bind:skin="selected_skin"
                v-bind:language="language"
                v-bind:translation="translation"
            />
        </div>
        <div v-if="screen_width<selected_skin.break_point" :style="'height:'+headerHeight"></div>
        <div v-if="view == 'main'" class="row g-0">
            <div v-if="screen_width>selected_skin.break_point" class="col-lg-2 col-md col-sm " :style="(screen_width<selected_skin.break_point?'':'height:'+contentHeight+'; overflow: auto;width:22%;')+selected_skin.fondo0">
                <main-ligas
                    v-if="prematchTarget==1"
                    v-bind:skin="selected_skin"
                    v-bind:language="language"
                    v-bind:translation="translation"
                />
                <main-ligas-2
                    v-else
                    v-bind:skin="selected_skin"
                    v-bind:language="language"
                    v-bind:translation="translation"
                />
                <div class="m-1 mb-2" :style="selected_skin.menu_live.body" style="overflow:hidden">
                    <div class="p-2" :style="selected_skin.menu_live.header">{{translate("live_menu")}}</div>
                    <eventoDeporteLive
                        v-for="(deporte, idx) in sports_live"
                        v-bind:deporte="deporte"
                        v-bind:Skin="selected_skin"
                        v-bind:key="idx"
                        v-bind:language="language"
                        v-bind:translation="translation"
                        >
                    </eventoDeporteLive>
                </div>
                <div class="m-1 mb-2" :style="selected_skin.menu_prematch.body" style="overflow:hidden">
                    <div class="p-2" :style="selected_skin.menu_prematch.header">{{translate("prematch_menu")}}</div>
                    <timeLine
                        v-if="screen_width>selected_skin.break_point && prematchTarget==1"  
                        v-bind:skin="selected_skin"
                        v-bind:language="language"
                        v-bind:translation="translation"/>
                    <div v-if="prematchTarget==0">
                        <schedule
                            v-bind:skin="selected_skin"
                            v-bind:language="language"
                            v-bind:translation="translation"
                        />
                        <div v-if="!timeLoading">
                            <eventoDeportePrematch
                            v-for="(deporte, idx) in sports_prematch"
                            v-bind:deporte="deporte"
                            v-bind:Skin="selected_skin"
                            v-bind:key="idx"
                            v-bind:language="language"
                            v-bind:translation="translation"
                            >
                            </eventoDeportePrematch>  
                        </div>
                        <div v-else class="text-center py-5">
                            <div class="spinner-border" :style="selected_skin.loader" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="events_data" class="col-lg-8 col-md col-sm p-0" :style="(screen_width<selected_skin.break_point?'overflow-x: hidden;':'height:'+contentHeight+'; overflow-x: hidden; overflow-y:auto;width:57%;')+selected_skin.fondo0">
                <!-- Lobby -->
                <div v-if="lobbyView=='main'">
                    <lobby
                        v-bind:skin="selected_skin"
                        v-bind:language="language"
                        v-bind:translation="translation"
                        v-bind:time="time"
                    ></lobby>
                </div>
                <!-- Ligas Prematch -->
                <div v-if="lobbyView=='prematch_ligas'">
                    <ligas-prematch
                        v-bind:skin="selected_skin"
                        v-bind:language="language"
                        v-bind:translation="translation"
                        v-bind:time="time"
                    ></ligas-prematch>
                </div>
                <div v-if="lobbyView=='prematch2_ligas'">
                    <ligas-prematch-2
                        v-bind:skin="selected_skin"
                        v-bind:language="language"
                        v-bind:translation="translation"
                    ></ligas-prematch-2>
                </div>
                <!-- Prematch Evento -->
                <div v-if="lobbyView=='prematch_event' && Object.keys(prematch_evento_data).length > 0">
                    <prematch-encuentro
                        v-bind:liga="prematch_evento_data.data"
                        v-bind:skin="selected_skin"
                        v-bind:sport="prematch_evento_data.data.deporte.id"
                        v-bind:time="time"
                        v-bind:language="language"
                        v-bind:translation="translation"
                        v-bind:search="{encuentro:prematch_evento_data.id}"
                    />
                </div>
                <div v-if="lobbyView=='prematch2_evento'">
                    <prematch-2-encuentro
                        v-bind:Skin="selected_skin"
                        v-bind:language="language"
                        v-bind:translation="translation"
                        v-bind:gameData="prematch2_gameData"
                    />
                </div>
                <!-- Ligas Live -->
                <div v-if="lobbyView=='live_ligas'">
                    <ligas-live
                        v-bind:skin="selected_skin"
                        v-bind:language="language"
                        v-bind:translation="translation"
                    />
                </div>
                <!-- Deportes -->
                <div v-if="lobbyView=='sport'">
                    <sport-view
                        v-bind:skin="selected_skin"
                        v-bind:language="language"
                        v-bind:translation="translation"
                        v-bind:sport_selected="sport_selected"
                    />
                </div>
                <!-- Historial -->
                <div v-if="lobbyView=='records'">
                    <main-records
                        v-bind:skin="selected_skin"
                        v-bind:language="language"
                        v-bind:translation="translation"
                        v-bind:user_id="{id:null,nav_bar:true}"
                        v-bind:source="'lobby'"
                    />
                </div>
            </div>
            <div class="col-lg-2 col-md-2 d-none d-lg-block" :style="(screen_width<selected_skin.break_point?'':'height:'+contentHeight+'; overflow-x: hidden; width:21%;')+selected_skin.fondo0">
                <main-search
                    v-bind:skin="selected_skin"
                    v-bind:language="language"
                    v-bind:translation="translation"
                ></main-search>
                <qbet
                    v-bind:skin="selected_skin"
                    v-bind:language="language"
                    v-bind:translation="translation"
                ></qbet>
                <div class="d-flex" v-if="selected_skin.banners.qbet_vertical.enabled">
                    <div v-if="screen_width>450" style="width:100%;position:relative;height:800px">
                        <!-- Carrousel Vertical -->
                        <iframe :src="selected_skin.banners.qbet_vertical.url" scrolling="no" frameborder="0" style="width:100%;position:relative;height:800px"></iframe>
                    </div>
                </div>
                <div v-if="debug" class="p-2">
                    <input v-model="follow_id" type="text" placeholder="event id"><button @click="follow">Seguir evento</button>
                    <button @click="set_bets()">{{ can_bet?'Desactivar Apuestas':'Activar Apuestas' }}</button>
                    <button @click="close_socket()">Cerrar Socket</button>
                </div>
            </div>
        </div>
        <div v-if="view == 'casino_live'" class="py-0">
            <div v-if="selected_skin.casinoLive_config?.provider=='jbets'" class="holds-the-iframe" :style="'height:'+contentHeight+';'">
                <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" :style="'height:'+contentHeight+';'" class="iframeStyle" ref="casino_frame" :src="casino_frame!=''?casino_frame:selected_skin.casinoLive_config?.lobby_url ?? ''" frameborder="0"></iframe>
            </div>
            <div v-else class="holds-the-iframe" :style="'height:'+contentHeight+';'">
                <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" :style="'height:'+contentHeight+';'" class="iframeStyle" ref="casino_frame" 
                :src="config.SkinCConfig.vivoUrl+'lobby/?token='+user.token+'&operatorid='+user.vivoOperatorId+'&serverid='+user.vivoServerId" frameborder="0"></iframe>
            </div>
        </div>
        <div v-if="view == 'crazzyWin'" class="py-0"> 
            <div v-if="selected_skin.crazzywin_config?.provider=='jbets'" class="holds-the-iframe" :style="'height:'+contentHeight+';'">
                <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" :style="'height:'+contentHeight+';'" class="iframeStyle" ref="casino_frame" :src="selected_skin.crazzywin_config.url+'&token='+user.token+user.site_id+'-'+user.site+'&playerId='+user.user_data.data.id+'_'+user.site_id+'&language='+language+'&currency='+user.user_data.data.currency" frameborder="0"></iframe>
            </div>
        </div>
        <div v-if="view == 'bingo'" class="py-0">
            <div v-if="selected_skin.bingo_config?.provider=='jbets'" class="holds-the-iframe" :style="'height:'+contentHeight+';'">
                <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" :style="'height:'+contentHeight+';'" class="iframeStyle" ref="casino_frame" :src="selected_skin.bingo_config.url+'&token='+user.token+user.site_id+'-'+user.site+'&playerId='+user.user_data.data.id+'_'+user.site_id+'&language='+language+'&currency='+user.user_data.data.currency" frameborder="0"></iframe>
            </div>
        </div>
        <div v-if="view == 'hipicas'" class="py-0">
            <!-- <div v-if="selected_skin.hipicas_config?.provider=='jbets'" class="holds-the-iframe" :style="'height:'+contentHeight+';'">
                <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" :style="'height:'+contentHeight+';'" class="iframeStyle" ref="casino_frame" :src="selected_skin.hipicas_config.url+'&token='+user.token+user.site_id+'-'+user.site+'&playerId='+user.user_data.data.id+'_'+user.site_id+'&language='+language+'&currency='+user.user_data.data.currency" frameborder="0"></iframe>
            </div>
            <div v-else :style="'height:'+contentHeight+';'">
                <iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" :style="'height:'+contentHeight+';'" class="iframeStyle" ref="casino_frame" 
                :src="config.SkinCConfig.racing.horse.url+'?tokenUser='+user.token+user.site_id+'&tokenClient=72b0fc93-fdd1-4c08-b1fd-0be81726a5d2&lang=es&cashierUrl='+user.domain+'&homeUrl='+user.domain" frameborder="0"></iframe>
            </div> -->
        </div>
        <div v-if="view == 'casino'" class="py-0">
            <div id="casinoViewContainer" v-if="selected_skin.casinoN_config?.provider=='jbets'" class="holds-the-iframe" :style="'height:'+contentHeight+';'">
                <iframe id="casinoViewIframe" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" :style="'height:'+contentHeight+';'" class="iframeStyle" ref="casino_frame" :src="selected_skin.casinoN_config.url+'&token='+user.token+user.site_id+'-'+user.site+'&playerId='+user.user_data.data.id+'_'+user.site_id+'&language='+language+'&currency='+user.user_data.data.currency" frameborder="0"></iframe>
            </div>
            <div v-else class="p-3" style="background-color:#08142b" :style="'height:'+contentHeight+';'">
                <carousel  
                :paginationEnabled="false"
                :autoplay="false"
                :navigationEnabled="true"
                :navigationNextLabel="next_label"
                :navigationPrevLabel="prev_label"
                :navigationClickTargetSize="0"
                :perPageCustom="[[300, 2],[451, 2],[1024, 7]]"
                class="p-0"
                >
                    <slide v-for="(provider,index) in config.SkinCConfig.providers" :key="index" class="text-center px-1 pe-1">
                        <div :style="selected_skin.casino.button" 
                        :id="index+'-provider'"
                        style="cursor:pointer;"
                        @click="select_casino(provider,index+'-provider')"
                        @mouseover="hover1(index+'-provider')" 
                        @mouseleave="!casino_frame.includes(index+'-provider') ? hover2(index+'-provider') : ''"
                        :class="casino_frame.includes(index+'-provider') ? 'hover' : ''">
                            <span style="font-size:small;">{{ provider.name.toUpperCase() }}</span>
                        </div>
                    </slide>
                </carousel>
                <div class="holds-the-iframe">
                    <iframe :style="'height:'+contentHeight+';'" ref="casino_frame" :src="casino_frame != '' ? (casino_frame != 'loading' ? casino_frame : '') : user.domain+'/casino/casino25/pragmatic?token='+user.token" frameborder="0"></iframe>
                </div>
            </div>
        </div>
        <div v-if="view == 'poker'">
            <div class="holds-the-iframe">
                <iframe :style="'height:'+contentHeight+';'" :src="user.domain+'/casino/poker/'+user.token+'/lobby?token='" frameborder="0"></iframe>
            </div>
        </div>
        <div v-if="view == 'results'">
            <div id="events_data" class="col-lg-8 col-md col-sm p-0" :style="(screen_width<selected_skin.break_point?'overflow-x: hidden;':'height:'+contentHeight+'; overflow-x: hidden; overflow-y:auto;width:100%;')+selected_skin.fondo0">
                <main-results
                v-bind:skin="selected_skin"
                v-bind:language="language"
                v-bind:translation="translation"
                /> 
            </div>
                       
        </div>
        <div v-if="view == 'lobby' && selected_skin.front_lobby.enabled">
            <div class="holds-the-iframe">
                <iframe :style="'height:'+contentHeight+';'" :src="selected_skin.front_lobby.url"></iframe>
            </div>
        </div>
    </div>
    <sidebar
        v-if="screen_width<selected_skin.break_point"
        v-bind:language="language"
        v-bind:translation="translation"
    >
    </sidebar>
    <div v-if="screen_width<selected_skin.break_point" style="z-index: 9999;" id="cupon_button" class="cupon_button text-center fade" :class="bet_counter>0 ? 'show' : ''" :style="selected_skin.qbet.mobil_btn" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
        <span><i class="far fa-list-alt"></i><br><span style="font-size:small">{{translate("coupon")}}</span></span>
        <span v-if="bet_counter > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {{ bet_counter }}
        </span>
    </div>
    <a v-if="selected_skin.whats_app.enabled" :href="'https://api.whatsapp.com/send?phone='+selected_skin.whats_app.phone_number" id="whatsapp_btn" class="float btn" target="_blank" :style="screen_width>selected_skin.break_point?'bottom:20px;right:20px;':'bottom:60px;left:20px;'">
        <img id="whatsapp_logo" class="" src="../../assets/images/whatsappBtn.png" alt="" width="50px">
    </a>
  </div>
</template>

<script>
import eventoDeporteLive from '../../components/front_ecuabets/main_lobby/main_menu/live/Evento_deporte_live.vue';
import eventoDeportePrematch from '../../components/front_ecuabets/main_lobby/main_menu/prematch2/Evento_deporte_prematch2.vue';
import lobby from '../../components/front_ecuabets/main_lobby/lobby.vue'
import timeLine from '../../components/front_ecuabets/main_lobby/main_menu/prematch1/Time_line_prematch.vue';
import qbet from '../../components/front_ecuabets/main_bet/Qbet.vue';
import mainSearch from '../../components/front_ecuabets/main_lobby/main_data/main_search.vue';
import sportView from '../../components/front_ecuabets/main_lobby/main_sport/sport_view.vue';
import ligasPrematch from '../../components/front_ecuabets/prematch/ligas_prematch.vue';
import ligasPrematch2 from '../../components/front_ecuabets/prematch2/ligas_prematch.vue';
import ligasLive from '../../components/front_ecuabets/live/ligas_live.vue';
import prematchEncuentro from '../../components/front_ecuabets/prematch/prematch_encuentro.vue';
import prematch2Encuentro from '../../components/front_ecuabets/prematch2/prematch_encuentro.vue';
import mainHeader from '../../components/front_ecuabets/header/header.vue';
import mainHeaderMobil from '../../components/front_ecuabets/header/header_mobil.vue';
import sidebar from '../../components/front_ecuabets/main_lobby/main_menu/Sidebar.vue';
import mainLigas from '../../components/front_ecuabets/main_lobby/main_data/main_ligas.vue';
import mainResults from '../../components/front_ecuabets/main_lobby/main_results/main_results.vue';
import mainLigas2 from '../../components/front_ecuabets/main_lobby/main_data/main_ligas2.vue';
import schedule from '../../components/front_ecuabets/main_lobby/main_menu/schedule.vue';
import mainRecords from '../../components/front_ecuabets/main_lobby/main_records/main_records.vue';
import { Carousel, Slide } from 'vue-carousel';
import router from '../../router/index';
export default {
    name: 'Main',
    components: {
        eventoDeporteLive,
        eventoDeportePrematch,
        qbet,
        timeLine,
        mainSearch,
        lobby,
        ligasPrematch,
        ligasPrematch2,
        prematchEncuentro,
        prematch2Encuentro,
        ligasLive,
        sportView,
        mainHeader,
        mainHeaderMobil,
        sidebar,
        mainLigas,
        mainLigas2,
        mainResults,
        Carousel,
        Slide,
        schedule,
        mainRecords,
    },
    data(){
        return {
            message: "",
            sports_loaded: false,
            screen_width:0,
            follow_id:"",
            flag:'es',
            debug:false,
            prematch_evento_data:{},
            prematch2_gameData:{},
            sport_selected:{},
            casino_brand_selected:"0-pragmatic",
            casino_frame:"",
            next_label:"<span style='color:white;'>▶</span>",
            prev_label:"<span style='color:white;'>◀</span>",
            refreshHeight: 0,
            casinoUserId: '655d4ae63ad36797f65fabad'
        }
    },
    computed:{
        contentHeight(){
            this.refreshHeight;
            let header_height = this.$store.getters["getHeaderHeight"];
            let screen_height = window.innerHeight;
            return (screen_height - header_height)+"px"; 
        },
        headerHeight(){
            return this.$store.getters["getHeaderHeight"] +"px"
        },
        config(){
            return this.$store.getters['getConfig'];
        },
        lobbyView(){
            return this.$store.getters['getLobbyView'];
        },
        prematchTarget(){
            return this.$store.getters['getPrematchTarget'];
        },
        can_bet(){
            let data = this.$store.getters['getCanBet'];
            return data;
        },
        selected_skin(){
            return this.$store.getters['getCurrentSkin'];
        },
        skin_charged(){
            return this.$store.getters['getSkinCharged'];
        },
        language(){
            return this.$store.getters['getLanguage'];
        },
        sports_live(){
            return  this.$store.getters['live/getSports'];
        },
        sports_prematch(){
            return  this.$store.getters['prematch_2/getSports'];
        },
        favoritos(){
            return this.$store.getters['live/getFavorites']; 
        },
        target_event(){
            if(Object.keys(this.$store.getters['live/getLiveEvent']).length > 0){
                return this.$store.getters['live/getLiveEvent'].data.data.sport;
            }
            return false;
        },
        translation(){
            return this.$store.getters.getTranslation;
        },
        time(){
            return this.$store.getters['prematch/getTime'];
        },
        timeLoading(){
            return this.$store.getters['prematch_2/getTimeLoading'];
        },
        view(){
            return this.$store.getters["getView"];
        },
        user(){
            let data = this.$store.getters['getUserData'];
            return data;
        },
        bet_counter(){
            let count = 0; 
            let data = this.$store.getters['getBets'];
            Object.keys(data).forEach(element => {
                if(data[element]){
                count++;
                }
            });
            let bar = document.getElementById('navbarToggleExternalContent');
            if(count<1 && bar){
                bar.classList.remove('show');
            }
            return count;
        }
    },
    created(){
        this.sizeControl();
        let self = this;
        document.addEventListener('keydown', function(event) {
        if (event.ctrlKey && event.key === 'j') {
            self.debug = !self.debug;
        }
        });
        let balanceRequestTime = 60000
        let formated = router.currentRoute.query.target;
        if(formated.includes("-")){
            if(formated.split("-")[2] != 'integration'){
                balanceRequestTime = 10000
            }
        }
        var saldo_request = setInterval(function(){
            try {
                if(self.$store.getters['getUserData'].user_data.data){
                    self.$store.dispatch('getUserData',{
                    token:self.user.token,
                    site_id:self.user.site_id,
                    site:self.user.site,
                    prefix:self.user.prefix,
                    source:"main"
                });
                }
            } catch (error) {
                this.$store.dispatch('logOut');
                this.$root.$emit('forceRender');
                clearInterval(saldo_request);
            }
        },balanceRequestTime);
    },
    mounted(){
        window.addEventListener('resize', ()=>{this.sizeControl();});
        window.addEventListener("message", (event) => {
            if(event.data.view == 'casino_live'){
                if(this.user.token == ''){
                        this.login();
                }
                else{
                    this.$store.dispatch('setView',event.data.view);
                    this.casino_frame = this.selected_skin.casinolive_lobby?.url ?? ""
                }
            }
            else if(event.data.view == 'bingo'){
                if(this.user.token == ''){
                        this.login();
                }
                else{
                    this.$store.dispatch('setView','bingo');
                    this.casino_frame = this.selected_skin.casinoN_config.url+'?token='+this.user.token+this.user.site_id+'&site='+this.user.site+'&lang='+this.language+'&currency='+this.user.user_data.data.currency+'&view=bingo';
                }
            }
            else if(event.data.view == 'crazywin'){
                if(this.user.token == ''){
                        this.login();
                }
                else{
                    this.$store.dispatch('setView','crazzyWin');
                    this.casino_frame = this.selected_skin.casinoN_config.url+'?token='+this.user.token+this.user.site_id+'&site='+this.user.site+'&lang='+this.language+'&currency='+this.user.user_data.data.currency+'&view=crazzyWin';
                }
            }
            else if(event.data.view == 'casino_live-pragmaticplay'){
                if(this.user.token == ''){
                        this.login();
                }
                else{
                    this.$store.dispatch('setView','casino_live');
                    this.casino_frame = this.selected_skin.casinoLive_config.casino_url+'&token='+this.user.token+this.user.site_id+'-'+this.user.site+'&playerId='+this.user.user_data.data.id+'_'+this.user.site_id+'&language='+this.language+'&currency='+this.user.user_data.data.currency+'&viewId='+this.selected_skin.casinoLive_config.pragmaticLive;
                }
            }
            else if(event.data.view == 'casino_live-ezugi'){
                if(this.user.token == ''){
                        this.login();
                }
                else{
                    this.$store.dispatch('setView','casino_live');
                    this.casino_frame = this.selected_skin.casinoLive_config.casino_url+'&token='+this.user.token+this.user.site_id+'-'+this.user.site+'&playerId='+this.user.user_data.data.id+'_'+this.user.site_id+'&language='+this.language+'&currency='+this.user.user_data.data.currency+'&viewId='+this.selected_skin.casinoLive_config.ezugiLive;
                }
            }
            else if(event.data.view == 'casino_live-vivogaming'){
                if(this.user.token == ''){
                        this.login();
                }
                else{
                    this.$store.dispatch('setView','casino_live');
                    this.casino_frame = this.selected_skin.casinoLive_config.casino_url+'&token='+this.user.token+this.user.site_id+'-'+this.user.site+'&playerId='+this.user.user_data.data.id+'_'+this.user.site_id+'&language='+this.language+'&currency='+this.user.user_data.data.currency+'&viewId='+this.selected_skin.casinoLive_config.vivogamingLive;
                }
            }
            else if(event.data.view == 'hipicas'){
                if(this.user.token == ''){
                        this.login();
                }
                else{
                    if (this.selected_skin.hipicas_config?.provider=='jbets') {
                        const url = this.selected_skin.hipicas_config.url+'&token='+this.user.token+this.user.site_id+'-'+this.user.site+'&playerId='+this.user.user_data.data.id+'_'+this.user.site_id+'&language='+this.language+'&currency='+this.user.user_data.data.currency
                        window.open(url,'_blank')
                    }
                    else {
                        const url = this.config.SkinCConfig.racing.horse.url+'?tokenUser='+this.user.token+this.user.site_id+'&tokenClient=72b0fc93-fdd1-4c08-b1fd-0be81726a5d2&lang=es&cashierUrl='+this.user.domain+'&homeUrl='+this.user.domain
                        window.open(url,'_blank')
                    }
                    // this.$store.dispatch('setView','hipicas');
                    
                }
            }
            else if(event.data.view == 'main'){
                this.$store.dispatch('setLobbyView',event.data.view);
                this.$store.dispatch('setView',event.data.view);
            }
            else if(event.data.view == 'register'){
                this.register();
            }
            else if(event.data.view == 'poker'){
                if(this.user.token == ''){
                        this.login();
                }
                else{
                    this.$store.dispatch('setView',event.data.view);
                }
            }
            else if(event.data.view == 'casino'){
                if(this.user.token == ''){
                    this.login();
                }
                else{
                    this.$store.dispatch('setView',event.data.view);
                    // const iframe = document.getElementById("casinoViewIframe")
                    // if(iframe){
                    //     const alturaContenido = iframe.contentWindow.document.body.scrollHeight;
                    //     iframe.style.height = alturaContenido + 'px';
                    // }
                }
            }
            else if(event.data.view == 'open_game'){
                this.select_event(event.data.gameData)
            }
        }, false);
        this.$root.$on('show_prematch_ligas', (type) => {
            if(type==1){
                this.$store.dispatch('prematch/delete_all_ligas');
            }
        });
        this.$root.$on('select_sport', (data) => {
            this.sport_selected=data;
        });
        this.$root.$on('set_prematch_event', (data) => {
            this.prematch_evento_data = {data:data.data,id:data.id};
        });
        this.$root.$on('show_prematch2_event', (data) => {
            this.prematch2_gameData= data;
        });
        console.log(this.sports_prematch)
    },
    methods:{
        set_bets(){
            this.$store.dispatch("setCanBet");
        },
        close_socket(){
            this.$store.dispatch("closeSocket");
        },
        follow(){
            this.$store.dispatch('live/setFollowId', this.follow_id);
            this.follow_id = "";
        },
        sizeControl(){
            this.screen_width=window.innerWidth;
            this.refreshHeight ++;
        },
        select_casino(target,id){
            let parameters = "";
            if(typeof target.parameters === 'object'){
                Object.keys(target.parameters).forEach(param=>{
                    parameters += "&" + param + "=" + target.parameters[param];
                })
            }
            this.casino_frame = target.url+'?token='+this.user.token+parameters;
            let previus = this.casino_brand_selected.toString();
            this.casino_brand_selected = id;
            this.hover2(previus);
        },
        hover1(id){
            document.getElementById(id).style.backgroundColor = this.selected_skin.prematch_all_odds.hover_fondo;
            document.getElementById(id).style.color = this.selected_skin.prematch_all_odds.hover_text;
        },
        hover2(id){
            if(id != this.casino_brand_selected && document.getElementById(id)){
                document.getElementById(id).style = this.selected_skin.casino.button;
            }
        },
        translate(text) {
            if (this.translation[text]) {
                return this.translation[text][this.language];
            }
            return text;
        },
        login(){
            document.getElementById('login_btn').click();
        },
        register(){
            document.getElementById('register_btn').click();
        },
        async select_event(game){
            try {
                let event_data = null;
                let isLive = false;
                let event_data_prematch = await this.$store.dispatch('prematch_2/getPrematchEvent',{game_id:parseInt(game.game_id),sport_id:game.sport_id});
                if(event_data_prematch){
                    event_data_prematch.sport = game.sport_id;
                    event_data_prematch.liga = game.competition_name;
                    event_data_prematch.sport_alias = game.sport_name;
                    event_data=event_data_prematch;
                }
                else{
                    let event_data_live = await this.$store.dispatch('live/getLiveEvent',{game_id:parseInt(game.game_id),sport_id:game.sport_id}); 
                    if(event_data_live){event_data=event_data_live;isLive=true;}
                }
                if(event_data){
                    if(!isLive){
                        if(router.history.current.name === 'Live_ecuabets' || router.history.current.name === 'LiveMainEcuabets'){
                            router.push({ name: 'Main_ecuabets', query: { token: this.user.token+this.user.site_id} })
                            let self  = this;
                            setTimeout(function(){
                                event_data.sport = game.sport_id;
                                self.$store.dispatch('setView','main');
                                self.$store.dispatch('setLobbyView','prematch2_evento');
                                self.$root.$emit('show_prematch2_event',event_data);
                                self.$store.dispatch('prematch_2/unsuscribe_all_events');
                                self.$store.dispatch('prematch_2/suscribe_event', parseInt(game.game_id));
                            },100)
                        }
                        else {
                            this.$store.dispatch('setView','main');
                            this.$store.dispatch('setLobbyView','prematch2_evento');
                            this.$root.$emit('show_prematch2_event',event_data);
                            this.$store.dispatch('prematch_2/unsuscribe_all_events');
                            this.$store.dispatch('prematch_2/suscribe_event', parseInt(game.game_id));
                        }
                        
                    }
                    else{
                        if(router.history.current.name !== 'Live_ecuabets' && router.history.current.name !== 'LiveMainEcuabets'){
                            router.push({ name: 'Live_ecuabets', query: { token: this.user.token+this.user.site_id} })
                            let self  = this;
                            setTimeout(function(){
                                if(Object.keys(self.$store.getters['live/getLiveEvent']).length > 0){
                                    self.$store.dispatch('live/unsuscribe_event');
                                }
                                self.$store.dispatch('live/suscribe_event', parseInt(game.game_id));
                            },100)
                        }
                        if(this.$store.getters['live/getTargetEventId'] !=  game.game_id){
                            if(Object.keys(this.$store.getters['live/getLiveEvent']).length > 0){
                                this.$store.dispatch('live/unsuscribe_event');
                            }
                            this.$store.dispatch('live/suscribe_event', parseInt(game.game_id));
                        }
                    }
                    if(document.getElementById("myAccountCloseBtn")){
                        document.getElementById("myAccountCloseBtn").click();
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>

<style>
  @import '../../assets/front_ecuabets/Styles.css';
  @import '../../assets/phoca-flags.css';
  @import '../../assets/phoca-styles.css';
  /* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(102, 101, 101); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(102, 101, 101);  
}
iframe{
    width: 100%;
    position: absolute;
    border: 0;
}
.cupon_button{
    position:fixed;
	width:60px;
	height:60px;
	bottom:60px;
	right:20px;
    z-index:100;
    border-radius: 50%;
}
.holds-the-iframe {
  background:url('~@/assets/front_ecuabets/images/loading.gif') center center no-repeat;
  min-height: 200px;
  width: 100%;
  overflow-x: hidden;
}
.float{
	position:fixed;
	width:60px;
	height:60px;
   z-index:100;
}
</style>